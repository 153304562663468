import jwtDecode from 'jwt-decode'
import { observable, action, runInAction, makeObservable } from 'mobx'
import { parse } from 'query-string'
import { makeObservableDef } from '../../store/makeObservableDef'

const defaultOptions = {
  loginPath: 'oauth/login',
  refreshPath: 'oauth/access_token',
  passwordPath: 'api/me/password',
  forcePasswordPath: 'api/me/force_password',
  resetPasswordPath: 'api/me/reset_password',
  storage: localStorage
}

export class LoginStore {

  loading = false

  constructor(options) {
    this.initialized = false
    this.options = Object.assign({}, defaultOptions, options)
    makeObservableDef(this, {
      initialized: observable,
      loading: observable,
      user: observable.ref,
      accessToken: observable,
      onResponse: action,
      login: action,
      resetPassword: action,
      setLoading: action,
      setAccessToken: action.bound,
      refresh: action.bound,
      logout: action.bound
    })
  }

  setLoading(loading) {
    this.loading = loading
  }

  async login(credentials) {
    this.setLoading(true)
    let response
    try {
      const o = this.options
      response = await fetch(o.base + o.loginPath, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(credentials)
      })
    } catch (ex) {
      this.setLoading(false)
      throw Error('failed to fetch')
    }
    await this.onResponse(response)
  }

  async onResponse(response) {
    if (response.status !== 200) {
      this.logout()
      throw Error('authentication failed')
    }
    const tokens = await response.json()
    this.options.storage.refreshToken = tokens.refresh_token
    this.setAccessToken(tokens.access_token)
  }

  setAccessToken(token) {
    this.initialized = true
    this.loading = false
    this.accessToken = token
    this.user = token ? jwtDecode(token) : null
  }

  get authenticated() {
    return Boolean(this.user)
  }

  get subject() {
    return this.user && this.user.sub
  }

  get memberId() {
    return this.user && this.user.mid
  }

  hasScope(scope) {
    const u = this.user
    return u && u.scopes && u.scopes.includes(scope)
  }

  logout() {
    delete this.options.storage.refreshToken
    this.setAccessToken(null)
  }

  async changePassword(change, force) {
    if (!this.authenticated) throw Error('not authenticated')
    const o = this.options
    const response = await fetch(o.base + (force ? o.forcePasswordPath : o.passwordPath), {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + this.accessToken,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(change)
    })
    if (response.status !== 200) {
      throw Error('password change failed')
    }

  }

  async resetPassword(username) {
    this.setLoading(true)
    const o = this.options
    const response = await fetch(`${o.base}${o.resetPasswordPath}?username=${encodeURIComponent(username)}`)
    this.setLoading(false)
    if (response.status !== 200) {
      throw Error('password reset failed')
    }
  }

  async refresh(token = this.options.storage.refreshToken, hash = window.location.hash) {
    const { access_token } = parse(hash)
    if (access_token) {
      this.setAccessToken(access_token)
      return
    }
    if (token) {
      let response
      try {
        const o = this.options
        response = await fetch(o.base + o.refreshPath, {
          method: 'POST',
          body: new URLSearchParams('grant_type=refresh_token&refresh_token=' + token)
        })
      } catch (ex) {
        runInAction(() => this.initialized = true)
        throw ex
      }
      await this.onResponse(response)
    } else {
      this.initialized = true
    }
  }
}

