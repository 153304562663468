import { observable, computed, action, runInAction, makeObservable } from 'mobx'
import { Crew } from './crew'
import { Page } from '../../store/page'
import { makeObservableDef } from '../../store/makeObservableDef'


export class CrewStore {

  constructor(postgrest, filter) {
    this.page = new Page(Crew, postgrest, filter)
    this.page.perPage = undefined
    this.crew = observable.array()
    makeObservableDef(this, {
      loading: observable,
      load: action.bound,
      reload: action.bound,
      add: action.bound,
      options: computed
    })
  }

  async load() {
    if (this.loading !== undefined) return
    this.loading = true
    try {
      await this.page.load()
      runInAction(() => {
        this.loading = false
        this.crew.replace(this.page.content)
      })
    } catch {
      this.reload()
    }
  }

  async add(crew) {
    crew = crew.trim()
    if (crew.length === 0) throw Error('need name for crew')
    const newCrew = new Crew(await this.page.insert({ crew }))
    runInAction(() => this.crew.push(newCrew))
    return newCrew
  }

  get(id) {
    this.load()
    return this.crew.find(e => e.crew_id === id)
  }

  reload() {
    this.loading = undefined
  }

  get options() {
    this.load()
    return this.optionsFromCrew(this.crew)
  }

  optionsFromCrew(crew) {
    return crew.map(c => ({ key: c.crew_id, value: c.crew_id, text: c.crew }))
  }

  crewFromIds(ids) {
    if (ids.includes) {
      return this.crew.filter(e => ids.includes(e.crew_id))
    } else {
      return [this.get(ids)]
    }
  }

  renderLabel = (item, index, defaultLabelProps) => {
    const crew = this.get(item.key)
    if (!crew) return { content: item.text }
    const r = ({
      color: crew.color,
      content: item.text,
      icon: crew.closed ? 'lock' : undefined
    })
    return r
  }
}

