import { observable, action, computed, makeObservable } from 'mobx'
import { makeObservableDef } from './makeObservableDef'


// Attach on page 
export class CountryStore {

  _options = null

  constructor(client) {
    this.client = client
    makeObservableDef(this, {
      _options: observable,
      load: action.bound,
      options: computed
    })
  }

  load() {
    this._options = []
    this.client.fetch('country').then(response => {
      if (response.status < 200 || response.status >= 300) {
        console.log('Postgrest API failed', response)
        throw Error(response)
      }
      return response.json()
    }).then(action(data => {
      this._options = data.map(x => ({
        value: x.country,
        text: x.country
      }))
    }))
  }

  get options() {
    if (this._options == null) this.load()
    return this._options.slice()
  }
}
