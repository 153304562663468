import { observable, toJS, computed, makeObservable } from 'mobx'
import { makeObservableDef } from '../../store/makeObservableDef'


export class Crew {

  static _path = 'crew'
  static _key = 'crew_id'

  constructor(data) {
    if (data) this.dry = data
    makeObservableDef(this, {
      monthly_fee: observable,
      crew: observable,
      color: observable,
      comment: observable,
      consent: observable,
      crew_owner_id: observable,
      program_id: observable,
      closed: observable,
      timeslot: observable,
      dry: computed
    })
  }

  get dry() {
    return toJS(this)
  }

  set dry(data) {
    Object.assign(this, data)
  }
}

