import React from 'react'
import { Segment } from 'semantic-ui-react'
import { parse } from 'qs'
import loadable from '@loadable/component'

class DelayedSpinner extends React.Component {

  constructor(props) {
    super(props)
    this.state = { spinner: false }
  }

  componentDidMount() {
    this.timeout = setTimeout(() => this.setState({ spinner: true }), 200)
  }

  componentWillUnmount() {
    clearTimeout(this.timeout)
  }

  render() {
    return this.state.spinner ? (<Segment loading />) : null
  }
}

// Create loadable pages renderers to use with <Route render=.../>
function pageRenderer(page) {
  const [path, query] = page.split(/\?/, 2)
  const rest = query ? parse(query) : {}
  const LoaderComponent = loadable(() => import(`./${path}Page`), {
    fallback: <DelayedSpinner />,
    ssr: false
  })
  LoaderComponent.displayName = `page(${path})`
  return ({ staticContext, ...props }) => {
    return <LoaderComponent {...props} {...rest} />
  }
}

const renderers = {}

export function page(path) {
  return renderers[path] || (renderers[path] = pageRenderer(path))
}

