import React from 'react'
import { Provider, MobXProviderContext } from 'mobx-react'
import { PostgrestClient } from './store/postgrestClient'
import { CountryStore } from './store/country'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { page } from './pages'
import { ToastContainer } from 'react-toastify'
import moment from 'moment'
import 'moment/locale/hr'
import 'react-toastify/dist/ReactToastify.css'
import { LoginStore } from './pages/login/loginStore'
import { CrewStore } from './pages/crews/crewStore'
import { SettingStore } from './pages/settings/settingStore'
import { configure, reaction } from 'mobx'
import { initSupabase } from './supabase'


export const base = window.location.host === 'localhost:3000' 
  ? 'http://localhost:8080/' 
  : `/${window.location.host.split(/\./)[0]}/`
console.log('API base for', window.location.host, base)
const loginStore = new LoginStore({ base })

setTimeout(loginStore.refresh, 0)
setInterval(loginStore.refresh, 20 * 60 * 1000)

// Global setup
moment.locale('hr')
moment.updateLocale('hr', {
  longDateFormat: {
    LT: 'H:mm',
    LTS: 'H:mm:ss',
    L: 'DD.MM.YYYY.',
    LL: 'D. MMMM YYYY.',
    LLL: 'DD.MM.YYYY. HH:mm',
    LLLL: 'dddd, D. MMMM YYYY. H:mm'
  },
})

configure({
  //enforceActions: 'observed'
})

const headers = {}
reaction(
  () => loginStore.accessToken,
  token => { if (token) headers.Authorization = 'Bearer ' + token }
)

const postgrest = new PostgrestClient({ base: base + 'api/', headers })

const countryStore = new CountryStore(postgrest)

const crewStore = new CrewStore(postgrest)

const settingStore = new SettingStore(postgrest)
settingStore.refresh()

initSupabase(loginStore)
  
const stores = { postgrest, loginStore, countryStore, crewStore, settingStore }

export function useStores() {
  return React.useContext(MobXProviderContext)
}

reaction(
  () => settingStore.path('org').name, 
  name => document.title = name
)

export const App = () => (
  <Provider {...stores}>
    <Router>
      <Switch>
        <Route exact path='/print/uplatnica' render={page('balances/hub30Print')} />
        <Route exact path='/reset' render={page('login/resetPassword')} />
        <Route render={page('main')} />
      </Switch>
      <ToastContainer position='top-right' />
    </Router>
  </Provider>
)


