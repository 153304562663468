
const cacheDefaultOptions = { timeToLive: 10000 }

export class SimpleCache {

  constructor(options) {
    Object.assign(this, cacheDefaultOptions, options)
  }

  cache = {}

  get(key) {
    let value = this.cache[key]
    if (value && Date.now() > value.expires) {
      this.cache[value] = undefined
      value.expired = true
    }
    return value
  }

  set(key, value) {
    value.expires = Date.now() + this.timeToLive
    this.cache[key] = value
  }

  clear() {
    this.cache = {}
  }
}

export class NoCache {

  get(key) {
    return undefined
  }

  set(key, value) { }

  clear() { }
}
