import { observable, runInAction, computed, action, makeObservable } from 'mobx'
import defaultSettings from './default.json'
import { MERGE_DUPLICATES } from '../../store/postgrestClient.js'
import { toast } from 'react-toastify'
import { makeObservableDef } from '../../store/makeObservableDef'

function flatten(source, target = {}, prefix = '') {
  for (let k in source) {
    const v = source[k]
    if (typeof v === 'object') {
      flatten(v, target, prefix + k + '.')
    } else {
      target[prefix + k] = v
    }
  }
  return target
}

export class SettingStore {

  snapshot = null

  constructor(api) {
    this.api = api
    this.defaults = flatten(defaultSettings)
    this.settings = observable.map()
    makeObservableDef(this, {
      dry: computed,
      dirty: computed,
      snapshot: observable.ref,
      handleChange: action.bound
    })
  }

  get(key, def) {
    let value = this.settings.get(key) || this.defaults[key]
    if (value && value.substring(0, 2) === '@@') {
      value = this.get(value.substring(2))
    }
    if (value === undefined) {
      value = def === undefined ? ('[' + key + ']') : def
      //console.log('M', key)
    }
    //console.log('G', key, this.settings.get(key), this.defaults[key], '->', value)
    return value
  }

  path(key, def) {
    return new Proxy(this, {
      get(target, name) {
        return target.get(key + '.' + name, def)
      }
    })
  }

  names() {
    return Array.from(this.settings.keys())
  }

  get dirty() {
    console.log('X', Object.fromEntries(this.settings))
    return JSON.stringify(this.snapshot) !== JSON.stringify(this.dry)
  }

  get dry() {
    return Object.fromEntries(this.settings)
  }

  handleChange(ev, { name, value }) {
    this.settings.set(name, value !== '' ? value : undefined)
  }

  save = async () => {
    const { settings, snapshot, api } = this
    // Keys to delete
    const toDelete = []
    for (let name in this.snapshot) {
      if (settings.get(name) === undefined) toDelete.push(name)
    }
    // Settings to update
    const toUpdate = []
    for (let name of settings.keys()) {
      //if (this.settings.get(k) === undefined) toDelete.push(k)
      const value = settings.get(name)
      if (value && snapshot[name] !== value) toUpdate.push({ name, value })
    }
    // console.log('S', toUpdate, toDelete)
    try {
      await Promise.all([
        toDelete.length > 0 ? api.delete(`setting?name=in.(${toDelete.join(',')})`) : null,
        toUpdate.length > 0 ? api.post('setting', toUpdate, MERGE_DUPLICATES) : null
      ])
      toast('Postavke spremljene')
      await this.refresh()
    } catch (e) {
      toast.error('Greška prilikom spremanja postavki' + e)
    }
  }

  refresh = async () => {
    const response = await this.api.fetch('setting')
    if (!response.ok) return
    const data = await response.json()
    const settings = (data || []).reduce((a, { name, value }) => {
      a[name] = value
      return a
    }, {})
    runInAction(() => {
      this.snapshot = settings
      this.settings.replace(settings)
    })
  }
}

