import { PostgrestClient } from '@supabase/postgrest-js'
import { reaction } from 'mobx'
import { useAsync } from 'react-async'

let client = null

export function initSupabase(store) {
  reaction(
    () => store.accessToken,
    token => {
      if (!token) return
      const base = store.options.base
      // console.log('Creating supabase client', base, token)
      client = new PostgrestClient(base + 'api', {
        headers: { Authorization: `Bearer ${token}` }
      })
    }
  )
}

export function useSupabase(apiFn, params) {
  return useAsync({ promiseFn: apiFn, client, ...params })
}

export function useSupabaseDefer(apiFn, params) {
  return useAsync({ deferFn: apiFn, client, ...params })
}

export function supabase(apiFn, params) {
  return apiFn({ client, ...params })
}
